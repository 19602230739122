.home {
  overflow: hidden;
  position: relative;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.background {
  z-index: -1;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: null;
  
}

@font-face {
  font-family: 'Orbitron';
  src: url('./Orbitron/static/Orbitron-Regular.ttf') format('truetype'); /* Adjust the path based on your project structure */
  font-weight: normal;
  font-style: normal;
}