.blog-background {
    top: 35%;
    position: fixed;
    transform: translateX(-10%);
    align-items: center;
    display: flex;
    flex-direction: column;
}

.spaceStyleSubheadingBlog {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 36px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
  }

  