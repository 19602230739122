.background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 300vw 300vh;
  backdrop-filter: blur(1rem);
  animation: gradient_301 5s ease infinite;
  background-image: linear-gradient(#212121, #212121),  linear-gradient(137.48deg, #ffdb3b 10%,#FE53BB 45%, #8F51EA 67%, #0044ff 87%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: auto;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

.spaceStyle {
  z-index: 2;
  font-family: 'Avalors Personal Use';
  font-size: 36px;
  letter-spacing: .25vw;
  color: #FFFFFF;
  text-shadow: 0 0 .25vw white;
  cursor: pointer;
  border: none;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  position: relative;
}

.spaceStyle:focus,
.spaceStyle:hover {
  color: #fff;
}

.spaceStyle:focus:after,
.spaceStyle:hover:after {
  width: 350px;
  left: 10%;
}

.spaceStyle:after {
  content: "";
  pointer-events: none;
  bottom: -15%;
  left: 50%;
  position: absolute;
  width: 0%;
  align-items: center;
  height: .25vh;
  background-color: #fff;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}



.underline {
  text-decoration: underline;
}

#glow {
  position: absolute;
  display: flex;
  width: 100vw;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.navigationMenu {
  text-align: center;
  position: fixed;
  z-index: 2;
  top: 15vh;
  left: 5vw;
  width: 400px;
  height: 70vh;
  display: flex ;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (max-width: 992px) {
  .navigationMenu {
    text-align: center;
    position: fixed;
    z-index: 2;
    top: 15vh;
    left: 0vw;
    width: 400px;
    height: 70vh;
    display: flex ;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .spaceStyle {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 30px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
    cursor: pointer;
    border: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
    position: relative;
  }
  

}


@media (max-width: 1200px) {
  .navigationMenu {
    text-align: center;
    position: fixed;
    z-index: 2;
    top: 15vh;
    left: 10vw;
    width: 200px;
    height: 70vh;
    display: flex ;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .spaceStyle {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 26px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
    cursor: pointer;
    border: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
    position: relative;
  }

  .spaceStyle:focus,
  .spaceStyle:hover {
    color: #fff;
  }

  .spaceStyle:focus:after,
  .spaceStyle:hover:after {
    width: 200px;
    left: 0%;
  }

  .spaceStyle:after {
    content: "";
    pointer-events: none;
    bottom: -15%;
    left: 50%;
    position: absolute;
    width: 0%;
    align-items: center;
    height: .25vh;
    background-color: #fff;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}
  
}

@media (max-width: 576px) {
  .navigationMenu {
    text-align: center;
    position: fixed;
    z-index: 2;
    top: 15vh;
    left: -20vw;
    width: 400px;
    height: 70vh;
    display: flex ;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .spaceStyle {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 30px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
    cursor: pointer;
    border: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
    position: relative;
  }

  .spaceStyle:focus,
  .spaceStyle:hover {
    color: #fff;
  }

  .spaceStyle:focus:after,
  .spaceStyle:hover:after {
    width: 150px;
    left: 30%;
  }

  .spaceStyle:after {
    content: "";
    pointer-events: none;
    bottom: -15%;
    left: 50%;
    position: absolute;
    width: 0%;
    align-items: center;
    height: .25vh;
    background-color: #fff;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  }
  
  
}