.home-background {
    color: #ffffff;
    padding: 1rem;
    margin: 1rem;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 900px;
    left: 30vw;
    top: 0vh;
    position: fixed;
}

.profilePicture {
    width: 300px;
    height: 300px;
    border-radius: 10%;
    margin-bottom: 1rem; /* Added margin-bottom to add space between the picture and the name */
}

.nameBanner {
    width: 100%;
    margin: 0; /* Removed negative margin */
}

.spaceStyleHeading {
    margin: 0; /* Ensures there's no extra margin around the heading */
    padding: 0; /* Ensures there's no extra padding around the heading */
}

.spaceStyleSubheading {
    margin: 0.5rem 0; /* Adds a small margin between subheading and heading */
    padding: 0; /* Ensures there's no extra padding around the subheading */
}

.spaceStyleText {
    margin: 0.5rem 0; /* Adds a small margin between the paragraphs */
    padding: 0; /* Ensures there's no extra padding around the text */
    line-height: 1.5; /* Adjusts the line height for better readability */
}

