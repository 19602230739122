/* General styles */
body {
    font-family: Arial, sans-serif;
  }
  
  .touch-background {
    color: #ffffff;
    padding: 2rem;
    margin: 2rem auto;
    border-radius: 10px;
    width: 550px;
    text-align: center;
    align-items: center;
    transform: translateX(40%);

  }
  
  .headingTouch {
    margin-bottom: 1.5rem;
    color: #e0c3fc;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .form-group label {
    margin-bottom: 0.5rem;
    color: #d3d3d3;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .submit-button-container {
    display: flex;
    justify-content: center;
  }
  
  .submit-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    background-color: #ffcc00;
    color: #1a1a1a;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width:50%;
  }
  
  .submit-button:hover {
    background-color: #e6b800;
  }

.spaceStyleHeadingTouch {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 60px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
  }



.spaceStyleSubheadingTouch {
z-index: 2;
font-family: 'Avalors Personal Use';
font-size: 36px;
letter-spacing: .25vw;
color: #FFFFFF;
text-shadow: 0 0 .25vw white;
}

.spaceStyleTextTouch {
z-index: 2;
font-family: 'Avalors Personal Use';
font-size: 22px;
letter-spacing: .25vw;
color: #FFFFFF;
text-shadow: 0 0 .25vw white;
}

.error-message {
    color: red;
    margin-top: 1rem;
  }
  
  .success-message {
    color: green;
    margin-top: 1rem;
  }