.experience-background {
    color: #ffffff;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    align-items: center;
    text-align: center;
    width: 700px;
    top: 5vh;
    left: 35vw;
    position: fixed;
    background: linear-gradient(135deg, #2c3e50 0%, #1a1a1a 100%); 
    display: flex;
    flex-direction: column;
}
.heading {
    margin-top: 5%;
}
#project-template {
  position: relative;
  padding: 20px 0;
}

#project-template::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50px;
  width: 4px;
  background: #31708f;
}

.project-item {
  position: relative;
  margin: 20px 0;
  padding-left: 100px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

}

.project-item::before {
  content: '';
  position: absolute;
  top: 20px;
  right: -75px;
  width: 40px;
  height: 40px;
  background: #31708f;
  border-radius: 50%;
  border: 4px solid #f4b084;
}

.project-date {
  position: absolute;
  top: 20px;
  right: -200px;
  width: 120px;
  text-align: center;
  font-size: 1.0em;
  color: #ffffff;
}

.project-title {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.project-company {
  margin: 5px 0;
  font-size: 1.2em;
  color: #d3d3d3; /* Light gray for company names */

}

.project-company a {
  text-decoration: none;
  color: #d3d3d3; /* Light gray for links */
}

.project-description {
  font-size: 1em;
  line-height: 1.6;
  color: #d3d3d3; /* Light gray for descriptions */

}

