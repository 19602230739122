.portfolio-background {
    color: #ffffff;
    padding: 1rem;
    margin: 1rem;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 1100px;
    left: 25vw;
    top: 0vh;
    position: fixed;
    
  }
  
  .portfolio-heading {
    color: #e0c3fc;
    text-align: center;
    
  }
  
  .portfolio-container {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .portfolio-item {
    display: flex;
    align-items: left;
    background: #2c2c2c;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    width: 80%; /* Adjust width as needed */
    height: 400px;
    max-width: 1000px; /* Optional: set a maximum width */
    overflow: hidden;
  }
  
  .portfolio-image {
    width:90%; /* Adjust width as needed */
    height: 90%;
    border-radius: 10px 0 0 10px;
    padding: 15px;
  }
  
  .portfolio-description {
    padding: 20px;
    color: #d3d3d3;
    width: 60%; /* Adjust width as needed */
  }
  
  .portfolio-description h3 {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffcc00;
  }
  
  .portfolio-description p {
    font-size: 1em;
    line-height: 1.6;
  }

.spaceStyleHeadingPortfolio {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 60px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
  }


  .spaceStyleSubheadingPortfolio {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 36px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
  }

  .spaceStyleTextPortfolio {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 22px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
  }
 