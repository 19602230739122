.headingTech {
    margin-top: 5%;
    color: #e0c3fc; /* Soft pastel pink for heading */
    margin-bottom: -5px;
}

.tech-background {
    color: #ffffff;
    padding: 1rem;
    align-items: center;
    text-align: center;
    margin-top:-20px;
    width: 800px;
    top: 5vh;
    left: 40vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    display: display-evenly;
}

.spaceStyleHeadingTech {
    z-index: 2;
    font-family: 'Avalors Personal Use';
    font-size: 60px;
    letter-spacing: .25vw;
    color: #FFFFFF;
    text-shadow: 0 0 .25vw white;
  }



.spaceStyleSubheadingTech {
z-index: 2;
font-family: 'Avalors Personal Use';
font-size: 36px;
letter-spacing: .25vw;
color: #FFFFFF;
text-shadow: 0 0 .25vw white;
}

.spaceStyleTextTech{
z-index: 2;
font-family: 'Avalors Personal Use';
font-size: 22px;
letter-spacing: .25vw;
color: #FFFFFF;
text-shadow: 0 0 .25vw white;
}
 